@tailwind base;
@tailwind components;
@tailwind utilities;

/* .debug {
  outline-width: 1px;
  outline-style: solid;
  outline-color: red;
  border: 1px solid red;
} */

.main-background {
  background-image: url("/assets/img/bg1.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

html,
body {
  @apply main-background m-0 size-full touch-none overflow-y-hidden p-0;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #00000010;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  transition: background 0.75s;
  background: #d5ab2f;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffdd62;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Prevent zoom in on ios */
input[type='text'],
input[type='number'],
textarea {
  font-size: 16px!important;
}

.left_menu_bg {
  background: url("/assets/img/bg2.webp") no-repeat;
  background-size: cover;
  background-position: bottom;
}

.black_btn {
  border-radius: 25px;
  padding: 5px 15px;
  background-color: #212121;
  border: 1px solid #212121;
  transition: background-color ease 0.3s;
  color: #d5ab2f;
  font-weight: bold;
  text-align: center;
}

.black_btn:hover {
  background-color: #d5ab2f;
  color: #212121;
}

.my_investment_bg {
  background: linear-gradient(160deg, rgba(255, 250, 233, 1) 0%, rgba(255, 225, 121, 0.9) 100%);
}
.planSwiper {
  padding-bottom: 30px !important;
}
.planSwiper .swiper-pagination {
  bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.planSwiper .swiper-pagination-bullet-active {
  background-color: #d5ab2f;
  width: 11px !important;
  height: 11px !important;
  translate: all ease 0.3s;
}
.planSwiper .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
}

.cardSwiper .swiper-slide-shadow {
  box-shadow: none !important;
  background-color: transparent !important;
}
.debug {
  outline-width: 1px;
  outline-style: solid;
  outline-color: red;
  /* border: 1px solid red; */
}

div[data-slot="input-wrapper"],
button[data-slot="trigger"] {
  border-width: 1px;
  @apply shadow-none;
}

input[data-slot="input"]::placeholder,
span[data-slot="value"] {
  color: #bbbbbb;
  @apply shadow-none;
}

/* readonly */
input[data-slot="input"][readonly] {
  color: #bbbbbb;
  @apply shadow-none;
}

.standard-container {
  @apply container mx-auto sm:px-4 xl:max-w-screen-lg;
}

.mobile-padding {
  @apply sm:px-4;
}
